@import '~normalize.css';
@import "main.css";

:root {
  --c-black: #333;
  --c-red: #b03060;
  --c-teal: #008080;

  --c-gray: #919191;
  --c-gray-light: #b0b0b0;
  --c-gray-dark: #5c5c5c;

  --c-divider: #e5e5e5;

  --font-family: 'Avenir', 'Work Sans', sans-serif;

  --shadow-depth-3: 0 6px 24px rgba(0, 0, 0, 0.12), 0 2px 6px rgba(0, 0, 0, 0.08);
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

textarea {
  font-family: 'Avenir','Work Sans', sans-serif;
}

.item-enter {
  opacity: 0;
}

.item-enter-active {
  opacity: 1;
  transition: opacity 0.3s ease-in;
}

.item-exit {
  opacity: 1;
}

.item-exit-active {
  opacity: 0;
  transition: opacity 0.3s ease-in;
}
